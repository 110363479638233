<template>
    <svg
        height="13px"
        viewBox="0 0 13 13"
        width="13px"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
            <g>
                <circle cx="6.5" cy="6.5" fill="#2C54EA" r="6.5" />
                <g
                    fill="#FFFFFF"
                    fill-rule="nonzero"
                    transform="translate(6, 3)"
                >
                    <path
                        d="M0.504273504,1 C0.361823362,1 0.242165242,0.951566952 0.145299145,0.854700855 C0.0484330484,0.757834758 0,0.641025641 0,0.504273504 C0,0.367521368 0.0484330484,0.249287749 0.145299145,0.14957265 C0.242165242,0.0498575499 0.361823362,0 0.504273504,0 C0.641025641,0 0.757834758,0.0498575499 0.854700855,0.14957265 C0.951566952,0.249287749 1,0.367521368 1,0.504273504 C1,0.641025641 0.951566952,0.757834758 0.854700855,0.854700855 C0.757834758,0.951566952 0.641025641,1 0.504273504,1 Z M0.0215,7 L0.0215,2 L0.9785,2 L0.9785,7 L0.0215,7 Z"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>
