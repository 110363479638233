<template>
    <svg
        class="icon flex-shrink-0 flex-shrink-0 nav-icon"
        fill="none"
        height="16"
        style="fill: none !important"
        viewBox="0 0 10 16"
        width="10"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_273_33317)">
            <path
                d="M6.15275 1.60001L6.45467 1.60001C7.15467 1.70001 7.7999 2.00001 8.3999 2.60001C8.6999 2.90001 8.8999 3.20001 9.0999 3.60001C9.2999 4.00001 9.3999 4.30001 9.3999 4.70001C9.3999 4.70001 9.3999 4.90001 9.3999 5.30001C9.3999 5.80001 9.3999 6.50001 9.3999 8.00001C9.3999 9.50001 9.3999 8.20001 9.3999 8.30001C9.3999 9.90001 9.3999 10.6 9.3999 11.1C9.3999 11.5 9.3999 11.6 9.2999 11.9C8.8999 13.6 7.5999 14.9 5.9999 15.3C3.4999 15.9 0.999902 14.3 0.499902 11.7C0.499902 11.3 0.399902 11 0.399902 9.40001V7.90001V4.40001C0.399902 4.40001 0.399902 4.30001 0.399902 4.20001C0.399902 3.30001 0.399902 2.80001 0.399902 2.60001C0.399902 2.50001 0.399902 2.40001 0.399902 2.40001C0.399902 2.40001 0.399902 2.40001 0.399902 2.30001C0.599902 2.00001 0.953125 1.60001 1.21854 1.60001C1.21854 1.60001 1.1999 1.60001 1.3999 1.60001C1.4999 1.60001 1.6999 1.60001 1.9999 1.60001C2.4999 1.60001 3.0999 1.60001 3.6999 1.60001C4.2999 1.60001 4.8999 1.60001 5.3999 1.60001C5.5999 1.60001 5.8 1.60001 6 1.60001C6.1 1.60001 6.1999 1.60001 6.1999 1.60001"
                stroke="white"
            />
            <path
                d="M4.8 8.4V9.4C4.8 9.5 4.8 9.6 4.7 9.7C4.7 9.7 4.5 9.8 4.4 9.8H3.4C3.3 9.8 3.2 9.8 3.1 9.7C3.1 9.7 3 9.5 3 9.4V8.4C3 8.3 3 8.2 3.1 8.1C3.1 8.1 3.3 8 3.4 8H4.4C4.5 8 4.6 8 4.7 8.1C4.7 8.1 4.8 8.3 4.8 8.4ZM6.6 8H5.6C5.5 8 5.4 8 5.3 8.1C5.3 8.1 5.2 8.3 5.2 8.4V9.4C5.2 9.5 5.2 9.6 5.3 9.7C5.3 9.7 5.5 9.8 5.6 9.8H6.6C6.7 9.8 6.8 9.8 6.9 9.7C6.9 9.7 7 9.5 7 9.4V8.4C7 8.3 7 8.2 6.9 8.1C6.9 8.1 6.7 8 6.6 8ZM4.4 10.2H3.4C3.3 10.2 3.2 10.2 3.1 10.3C3.1 10.3 3 10.5 3 10.6V11.6C3 11.7 3 11.8 3.1 11.9C3.1 11.9 3.3 12 3.4 12H4.4C4.5 12 4.6 12 4.7 11.9C4.7 11.9 4.8 11.7 4.8 11.6V10.6C4.8 10.5 4.8 10.4 4.7 10.3C4.7 10.3 4.5 10.2 4.4 10.2ZM5.4 11.2H6H6.1C6.1 11.2 6.1 11.2 6.1 11.1V10.5V10.4C6.1 10.4 6.1 10.4 6 10.4H5.4H5.3C5.3 10.4 5.3 10.4 5.3 10.5V11.1V11.2C5.3 11.2 5.3 11.2 5.4 11.2ZM6.8 10.2H6.7C6.7 10.2 6.7 10.2 6.7 10.3V11.3V11.4C6.7 11.4 6.7 11.4 6.6 11.4H5.6H5.5C5.5 11.4 5.5 11.4 5.5 11.5C5.5 11.6 5.5 11.6 5.5 11.6C5.5 11.6 5.5 11.6 5.6 11.6H6.6C6.8 11.6 6.9 11.6 7 11.4C7.1 11.3 7.2 11.1 7.2 11V10V9.9C7.2 9.9 7.2 9.9 7.1 9.9L6.8 10.2Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_273_33317">
                <rect fill="white" height="16" width="10" />
            </clipPath>
        </defs>
    </svg>
</template>
