<template>
    <svg
        height="7px"
        viewBox="0 0 10 7"
        width="10px"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
            <g stroke="#F7D034" transform="translate(-71, -54)">
                <g transform="translate(71, 54)">
                    <rect height="2" width="9" x="0.5" y="0.5" />
                    <rect height="2" width="9" x="0.5" y="4.5" />
                </g>
            </g>
        </g>
    </svg>
</template>
